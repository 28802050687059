<template>
  <!-- 采购单详情 -->
  <div class="addPerson__wrap" v-show="visible" style="z-index: 10 !important">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="handleReturn">
          <el-image
            class="back-img"
            :src="require('@/assets/img/back.png')"
          ></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          采购单详情
        </div>
      </div>
    </el-header>

    <div class="main">
      <div class="mainBox">
        <div class="itemTime">基础信息</div>
        <el-descriptions title="">
          <el-descriptions-item label="采购来源">
            <span>{{ info.source == 1 ? "销售订单" : "平台自采" }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="计划交货日期">{{
            info.delivery_date
          }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{
            info.create_time
          }}</el-descriptions-item>
          <el-descriptions-item label="采购单号">{{
            info.order_no
          }}</el-descriptions-item>
          <el-descriptions-item label="采购状态">{{
            toStr(
              {
                0: "待发货",
                1: "待收货",
                2: "已取消",
                3: "部分收货",
                4: "全部收货",
              },
              info.status
            )
          }}</el-descriptions-item>
          <el-descriptions-item label="创建人">{{
            info.admin_name
          }}</el-descriptions-item>
          <el-descriptions-item label="地区">{{
            info.city_name
          }}</el-descriptions-item>
          <el-descriptions-item label="仓库名称" v-if="!!info.stash_name"
            >{{ info.stash_name }}（{{ info.stash_province
            }}{{ info.stash_city }}）</el-descriptions-item
          >
          <el-descriptions-item
            label="收货人"
            v-if="info.status != 2 && info.delivery_num > 0"
            >{{ info.delivery_name }}</el-descriptions-item
          >
          <el-descriptions-item
            label="供应商/采购员"
            v-if="!!info.supplier_name"
            >{{ info.supplier_name }}</el-descriptions-item
          >
          <el-descriptions-item label="发货时间" v-if="!!info.send_time">{{
            info.send_time
          }}</el-descriptions-item>
          <!-- 已关闭的不展示已收货数量和已收货数量 -->
          <el-descriptions-item
            label="已收货数量"
            v-if="info.status != 2 && info.delivery_num > 0"
            >{{ info.delivery_num }}</el-descriptions-item
          >
          <el-descriptions-item
            label="已收货金额"
            v-if="info.status != 2 && info.delivery_money > 0"
            >￥{{ info.delivery_money }}</el-descriptions-item
          >
          <el-descriptions-item label="完成时间" v-if="info.status == 4">{{
            info.complete_time
          }}</el-descriptions-item>
          <el-descriptions-item label="关闭时间" v-if="info.status == 2">{{
            info.cancel_time
          }}</el-descriptions-item>
        </el-descriptions>

        <div class="itemTime">
          收货<span style="color: #999; font-size: 14px">
            (共{{ table.total }}项)</span
          >
        </div>
        <el-input
          style="width: 240px; margin-bottom: 10px"
          v-model.trim="form.data.goods_name"
          placeholder="请输入商品名称"
          size="small"
          clearable
          @clear="getTable"
          @keydown.enter.native="getTable"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getTable"
          ></el-button>
        </el-input>
        <!-- is_sorter:1：是， 若供应商是支持代分拣的收货登记增加了食堂名称字段 -->
        <el-select
          v-if="info.is_sorter == 1"
          v-model="form.data.address_id"
          filterable
          :placeholder="`请输入${userId == 5 ? '用户' : '食堂'}名称`"
          style="width: 240px; margin-bottom: 10px; margin: 0 10px"
          clearable
          size="small"
          @focus="getStore"
          @change="getTable"
        >
          <el-option
            v-for="item in storeArr"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px"
              >({{ item.merchant_name }})</span
            >
          </el-option>
        </el-select>

        <el-table
          :data="tableData"
          style="width: 100%"
          :cell-style="{ background: '#fff' }"
          :span-method="objectSpanMethod"
          border
        >
          <el-table-column type="index" label="ID" fixed></el-table-column>
          <!-- 不支持分拣的供应商数据，不需要显示食堂名称字段 （来源销售订单也要展示食堂） -->
          <el-table-column
            v-if="info.is_sorter == 1"
            prop="store_name"
            label="食堂名称"
            fixed
            key="6"
          >
          </el-table-column>
          <el-table-column
            prop="good_img"
            label="商品名称"
            width="180px"
            fixed
            key="1"
          >
            <template slot-scope="{ row }">
              <div style="display: flex; align-items: center">
                <el-image
                  v-if="!!row.goods_image"
                  style="width: 40px; height: 40px"
                  :src="row.goods_image"
                  fit="cover"
                  :preview-src-list="[row.goods_image]"
                ></el-image>
                <el-image
                  v-else
                  style="width: 40px; height: 30px"
                  :src="require('@/assets/img/logo-icon.jpeg')"
                  fit="cover"
                  :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"
                ></el-image>
                <span style="margin-left: 10px">{{ row.goods_name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="code" label="商品编码" width="120px" key="2">
          </el-table-column>
          <el-table-column
            prop="order_cate_name"
            label="下单分类"
            key="3"
            min-width="120px"
          >
            <template slot-scope="{ row }">
              <span>{{ row.order_cate_name }}</span>
              <span v-if="!!row.order_tow_name">
                / {{ row.order_tow_name }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="unit_name" label="单位" key="4">
          </el-table-column>
          <el-table-column prop="spec_name" label="规格" key="5">
          </el-table-column>
          <el-table-column prop="status" label="采购状态" key="8">
            <template slot-scope="{ row }">{{
              toStr(
                {
                  1: "待收货",
                  2: "取消",
                  3: "删除",
                  4: "部分收货",
                  5: "全部收货",
                },
                row.status
              )
            }}</template>
          </el-table-column>
          <el-table-column
            prop="last_purchase_price"
            label="采购价"
            width="120px"
            key="9"
          >
            <template slot-scope="{ row, $index }">
              <!-- 全部收货时可编辑采购价 -->
              <template v-if="info.status == 4 && row.isEdit == true">
                <el-input
                  v-model="row.last_purchase_price"
                  placeholder="请输入"
                  size="small"
                  @input="countPrice(row, $index)"
                ></el-input>
              </template>
              <span v-else>{{ row.last_purchase_price }}</span>
              <div
                v-if="
                  row.extent_curr != 0 &&
                  row.old_purchase_price != 0 &&
                  row.isEdit == false
                "
                style="display: inline"
              >
                <i
                  :class="
                    row.extent_curr > 0
                      ? 'el-icon-top redColor'
                      : 'el-icon-bottom greenColor'
                  "
                ></i>
                <span
                  :class="
                    row.extent_curr > 0
                      ? 'extent redColor'
                      : 'extent greenColor'
                  "
                  >{{ Math.abs(row.extent_curr) }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="cov_unit"
            label="采购单位"
            width="110px"
            key="10"
          >
            <template slot-scope="{ row }">
              <!-- 没有采购单位就默认下单单位 -->
              <span>{{ !!row.cov_unit ? row.cov_unit : row.unit_name }}</span>
              <!-- 如果有单位转化就显示转化 -->
              <span v-if="row.cov_times != 0"
                >（{{ row.cov_times }}{{ row.unit_name }}）</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="num" label="下单数量" width="120px" key="12">
          </el-table-column>
          <el-table-column
            prop="delivery_num"
            label="已收货数量"
            width="100px"
            key="13"
          >
            <template slot-scope="{ row, $index }">
              <el-input
                v-if="info.status == 4 && row.isEdit == true"
                v-model="row.delivery_num"
                placeholder="请输入"
                size="small"
                @input="countPrice(row, $index)"
              ></el-input>
              <span v-else>{{ row.delivery_num }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="delivery_money"
            label="已收金额"
            width="120px"
            key="14"
          >
            <template slot-scope="{ row }">
              <el-input
                v-if="info.status == 4 && row.isEdit == true"
                v-model="row.delivery_money"
                placeholder="请输入"
                size="small"
                @input="
                  row.delivery_money = row.delivery_money.replace(/[^\d.]/g, '')
                "
              ></el-input>
              <span v-else>{{ row.delivery_money }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" width="180px" key="15">
            <template slot-scope="{ row }">
              <div v-for="(v, i) in row.remark" :key="i">{{ v }}。</div>
            </template>
          </el-table-column>
          <el-table-column prop="refund_num" label="退货数" key="16">
            <template slot-scope="{ row }">
              <el-link
                v-if="row.refund_num > 0"
                type="primary"
                @click="handleRefund(row)"
                >{{ row.refund_num }} <i class="el-icon-view"></i
              ></el-link>
            </template>
          </el-table-column>
          <!-- 全部收货状态才有操作 -->
          <el-table-column
            prop="action"
            label="操作"
            key="17"
            v-if="info.status == 4"
          >
            <template slot-scope="{ row, $index }">
              <el-button
                type="text"
                @click="confirm(row, $index)"
                :icon="row.isEdit == false ? 'el-icon-edit' : ''"
                >{{ row.isEdit == false ? "编辑" : "保存" }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <template v-if="!!info.bills && info.bills.length > 0">
          <div class="itemTime mt20">采购单据</div>
          <div class="mt20" v-for="(item, index) in info.bills" :key="index">
            <div>
              <span class="name">{{ item.name }} </span>
              <span class="time">{{ item.create_time }} </span>
            </div>
            <el-image
              v-for="img in item.bills"
              :key="img"
              style="
                width: 60px;
                height: 60px;
                margin-top: 10px;
                margin-right: 10px;
              "
              :src="img"
              :preview-src-list="item.bills"
            >
            </el-image>
          </div>
        </template>
      </div>
      <!-- currRow.showType !== 9999,从入库列表、采购汇总查看的采购单详情不需要操作 -->
      <div class="footerWrap" v-if="currRow.showType !== 9999">
        <!-- status 0:'待发货' 1:'待收货', 2:'已关闭', 3:'部分收货', 4:'全部收货'  -->
        <el-button
          v-if="this.info.status == 0"
          type="primary"
          plain
          size="medium"
          @click="handleCancel"
          >关闭</el-button
        >
        <el-button
          v-if="
            this.info.status == 0 ||
            this.info.status == 1 ||
            this.info.status == 3
          "
          type="primary"
          plain
          size="medium"
          @click="handleReceive"
          >收货</el-button
        >
        <el-button
          v-if="this.info.status != 2"
          type="primary"
          plain
          size="medium"
          @click="plexportDataGoods"
          >导出</el-button
        >
        <el-button type="primary" plain size="medium" @click="handleCopy"
          >复制</el-button
        >
      </div>
    </div>

    <el-dialog
      title="退货详情"
      width="500px"
      append-to-body
      :visible.sync="dialogTableVisible"
      center
      :close-on-click-modal="false"
    >
      <el-descriptions class="margin-top" title="" :column="1" border>
        <el-descriptions-item label="商品名称" :labelStyle="labelStyle">
          <div>{{ refundInfo.goods_name }}</div>
          <div style="color: #999; font-size: 12px">{{ refundInfo.code }}</div>
        </el-descriptions-item>
        <el-descriptions-item label="采购价" :labelStyle="labelStyle"
          >¥{{ refundInfo.last_purchase_price }}/{{
            refundInfo.unit_name
          }}</el-descriptions-item
        >
        <!-- 原收货数量 = 收货数量delivery_num + 退货数量refund_num-->
        <el-descriptions-item label="原收货数量" :labelStyle="labelStyle"
          >{{
            Number(refundInfo.delivery_num) + Number(refundInfo.refund_num)
          }}/{{ refundInfo.unit_name }}</el-descriptions-item
        >
        <el-descriptions-item label="退货数量" :labelStyle="labelStyle"
          >{{ refundInfo.refund_num }}/{{
            refundInfo.unit_name
          }}</el-descriptions-item
        >
        <el-descriptions-item label="退货金额" :labelStyle="labelStyle"
          >¥{{ refundInfo.refund_money }}</el-descriptions-item
        >
        <el-descriptions-item label="退货原因" :labelStyle="labelStyle">{{
          toStr(
            { 1: "质量问题", 2: "买多了/买错了", 3: "商品与要求不符" },
            refundInfo.refund_reason
          )
        }}</el-descriptions-item>
        <el-descriptions-item label="创建时间" :labelStyle="labelStyle">{{
          info.create_time
        }}</el-descriptions-item>
        <el-descriptions-item label="创建人" :labelStyle="labelStyle">{{
          info.admin_name
        }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import { getStorage } from "@/storage";
import { mixinTable } from "@/mixins/table.js";
export default {
  name: "purchaseDetail",
  mixins: [mixinTable],
  data() {
    return {
      userId: getStorage("userId"),
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      visible: false,
      labelStyle: { width: "100px" },
      info: {
        is_sorter: "", // 该供应商是否支持代分拣 1：是，2否
        bills: [],
        order: {},
      },
      tableData: [],
      table: {
        total: 0,
      },

      loading: false,
      form: {
        loading: false,
        data: {
          goods_name: "",
          address_id: "",
        },
      },
      multipleSelection: [],
      currRow: {},
      storeArr: [],

      pos: 0,
      spanArr: [], // 合并单元格
      dialogTableVisible: false,
      refundInfo: {},
    };
  },
  methods: {
    getDetail(row) {
      this.currRow = row;
      console.log(this.currRow.showType);
      this.getTable();
    },
    getTable() {
      let _params = {
        id: this.currRow.id,
        goods_name: this.form.data.goods_name,
        address_id: this.form.data.address_id,
        page: 1,
        count: 1000,
      };
      this.$http
        .get("/admin/purchase_order/info", { params: _params })
        .then((res) => {
          if (res.code == 1) {
            this.info = res.data.main;
            // this.tableData = res.data.details.list; // 商品信息
            this.tableData = res.data.details.list.map((v) => {
              return {
                extent_curr:
                  Math.round(
                    v.last_purchase_price * 100 - v.old_purchase_price * 100
                  ) / 100, // 计算采购价上升或下降
                isEdit: false, // 全部收货编辑的时候用
                ...v,
              };
            });

            // 支持分拣供应商且来源为销售订单的商品去重
            if (this.info.is_sorter == 1 && this.info.source == 1) {
              let some = [];
              this.tableData.forEach((el) => {
                if (
                  !some.some(
                    (e) => e.code == el.code && e.spec_name == el.spec_name
                  )
                ) {
                  some.push(el);
                }
              });
              this.table.total = some.length;
            } else {
              this.table.total = res.data.details.total;
            }

            this.pos = 0; // 初始化合并单元格数据
            this.spanArr = []; // 初始化合并单元格数据
            this.getSpanArr(this.tableData);
            this.$nextTick(() => {
              // 替换文本把食堂换成用户
              this.replaceTextInElement(this.$el, "食堂", "用户");
            });
          }
        });
    },
    reset(done) {
      done && done();
    },
    toggle(show) {
      this.visible = show;
      !show && this.reset();
    },
    // 获取食堂
    getStore() {
      this.$http
        .get("/admin/path/storeList", { params: { page: 1, count: 10000 } })
        .then((res) => {
          if (res.code === 1) {
            this.storeArr = res.data.list;
          }
        });
    },
    // 关闭
    handleCancel() {
      this.setConfirm(
        `确认关闭操作？`,
        { id: this.currRow.id },
        "/admin/purchase_order/cancel"
      );
    },
    // 导出
    plexportDataGoods() {
      let url = "/admin/purchase_order/download";
      let str = "";
      let obj = {
        id: this.currRow.id,
      };
      Object.keys(obj).forEach((item) => {
        str += `&${item}=${obj[item]}`;
      });
      window.open(
        `${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`
      );
    },
    // 收货
    handleReceive() {
      this.toggle(false);
      this.$parent.showReceive(this.currRow); // 调用父组件收货方法
    },
    // 复制
    handleCopy() {
      this.toggle(false);
      this.$parent.showAdd(this.currRow); // 调用父组件编辑方法
    },
    // 返回
    handleReturn() {
      this.form.data.goods_name = "";
      this.$emit("refresh");
      this.toggle(false);
      this.tableData = [];
    },
    handleRefund(row) {
      this.refundInfo = row;
      this.dialogTableVisible = true;
    },
    getSpanArr(data) {
      // data 是我们从后台拿到的数据
      for (var i = 0; i < data.length; i++) {
        if (i == 0) {
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          // 判断当前元素与上一个元素是否相同 (商品编码和规格一至的需要合并)
          if (
            data[i].code == data[i - 1].code &&
            data[i].spec_name == data[i - 1].spec_name
          ) {
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    // 当前行row、当前列column、当前行号rowIndex、当前列号columnIndex四个属性
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 支持分拣供应商且来源为销售订单的数据合并单元格
      if (this.info.is_sorter == 1 && this.info.source == 1) {
        if (columnIndex < 7 && columnIndex > 1) {
          const _row = this.spanArr[rowIndex];
          const _col = _row > 0 ? 1 : 0;
          // console.log(`rowSpan:${_row}, colspan:${_col}`)
          return {
            // [0,0] 表示这一行不显示，[2,1]标示这一行的合并数
            rowspan: _row,
            colspan: _col,
          };
        }
      }
    },
    confirm(row, index) {
      console.log(row.isEdit, "row.isEdit");
      if (row.isEdit == false) {
        console.log("1111");
        let _currRow = { ...row, isEdit: true };
        this.$set(this.tableData, index, { ..._currRow });
      } else {
        console.log("2222");
        let _currRow = { ...row, isEdit: false };
        this.$set(this.tableData, index, { ..._currRow });
        let _params = {
          detail_id: row.id, // 明细id
          purchase_price: row.last_purchase_price, //采购单价
          delivery_money: row.delivery_money, //收货金额
          delivery_num: row.delivery_num, //收货数量
        };
        this.$http
          .post("/admin/purchase_order/updateDetail", _params)
          .then((res) => {
            if (res.code == 1) {
              this.$message.success("操作成功！");
              this.getTable();
            }
          });
      }
    },
    // 计算价格
    countPrice(row, index) {
      const reg = /^[\d]+$/;
      // 任何单位的数量都可以输入一位小数
      if (row.delivery_num && !reg.test(row.delivery_num)) {
        row.delivery_num = row.delivery_num = row.delivery_num.match(
          /\d+(\.\d{0,2})?/
        )
          ? row.delivery_num.match(/\d+(\.\d{0,2})?/)[0]
          : "";
      }

      // 采购价格只允许输入两位小数
      if (row.last_purchase_price && !reg.test(row.last_purchase_price)) {
        row.last_purchase_price = row.last_purchase_price =
          row.last_purchase_price.match(/\d+(\.\d{0,2})?/)
            ? row.last_purchase_price.match(/\d+(\.\d{0,2})?/)[0]
            : "";
      }
      // 计算本次收货价格 = （采购价 * 收货数量）
      if (!!row.last_purchase_price && !!row.delivery_num) {
        row.delivery_money =
          Math.round(
            parseFloat(row.last_purchase_price * row.delivery_num) * 100
          ) / 100;
        this.$set(this.tableData, index, row);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
}
.addPerson__wrap .main {
  overflow-x: hidden;
  background: #fff;
}
.returnBtn {
  z-index: 99;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%),
    0 2px 4px 0 rgb(232 237 250 / 50%);
}
.footerWrap {
  text-align: center;
  padding: 10px;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%),
    0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 13px;
  color: #303030;
  text-align: right;
}

::v-deep .el-table tbody tr:hover > td {
  background-color: #fff !important;
}
.el-table tbody tr:hover > td {
  background-color: #fff !important;
}
</style>
